import React, { Component } from 'react'
import './Authentication.css'
import GoogleLogin from 'react-google-login'
// import Axios from 'axios'

// const splash = require('../../splash.JPG')

export default class Authentication extends Component {

    componentDidMount = () => {
        this.checkSavedUser()
    }

    checkSavedUser = () => {
        const account = JSON.parse(localStorage.getItem('account'))
        if (account) {
            this.props.onAuthenticationSuccess ? this.props.onAuthenticationSuccess(account) : null()
        }
    }

    onGoogleLogin = (response) => {

        const email = response.profileObj.email
        const address = email.split('@').pop()

        const account = {
            name: response.profileObj.name,
            authUser: response.tokenObj.session_state.extraQueryParams.authuser
        }

        if (address === 'thndr.app') {
            localStorage.setItem('account', JSON.stringify(account))
            this.props.onAuthenticationSuccess ? this.props.onAuthenticationSuccess(account) : null()
        } else {
            alert('Permission Denied')
        }

        // const data = {
        //     email: 
        // }

        // const options = {
        //     method: 'POST',
        //     headers: { 'Authorization': 'Api-Key FKk1GPeg.lk70dZbBdx6NogfVgDqX4xgvbLFnVlac' },
        //     data: ,
        //     url: 'http://35.210.248.143:8000/api/user/login/social',
        // }

        // Axios(options)
    }

    render() {
        return (
            <div className='splash-screen'>

                {/* <img src={splash} alt='' /> */}

                <GoogleLogin
                    className='google-button'
                    clientId="639172574829-el97t1joacua39om0uvm0sgndef168l4.apps.googleusercontent.com"
                    buttonText="Log In With thndr Email"
                    onSuccess={this.onGoogleLogin}
                    onFailure={(error) => console.log(error)}
                    cookiePolicy={'single_host_origin'}
                />

            </div>
        )
    }
}
