import React from 'react'
import './Header.css'
const logo = require('../../logo.png')

const Header = (props) => {
    return (
        <div className='header'>
            <img src={logo} alt='' />

            {
                props.name.length > 0 ?

                    <div className='name'>
                        <p>{props.name}</p>
                        <p>|</p>
                        <a onClick={() => { props.onLogout ? props.onLogout() : null() }}>Logout</a>
                    </div>

                    :

                    null
            }

        </div>

    )
}

export default Header