import React from 'react'
import './MenuCard.css'

export default function MenuCard(props) {

    const size = props.iconSize ? props.iconSize : '65%'

    return (

        <a href={props.target} target="_blank" className='menu-card' >

            <div className={`icon-container ${props.icon ? '' : 'hidden'}`}>
                {
                    props.icon ?
                        <img src={props.icon}
                            style={{
                                borderRadius: props.roundIcon ? '50%' : 0,
                                width: size,
                                height: size
                            }} alt='' />
                        :
                        null
                }
            </div>

            <p>{props.title}</p>

        </a>

    )
}
